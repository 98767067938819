import { toastr } from "react-redux-toastr";
import api, { adminApi } from "../api";
import {
  CREATE_MOD,
  DELETE_MOD,
  FETCH_COMMUNITIES,
  FETCH_COUNTRIES,
  FETCH_JOBS,
  FETCH_JOBS_PER_COMPANY,
  FETCH_KPIS,
  FETCH_MODS,
  FETCH_PENDING_CUSTOM_JOBS,
  FETCH_STATS,
  LOGIN,
  LOGOUT,
} from "./types";

export const getStats = () => async (dispatch) => {
  const stats = await api.get("stats");
  //console.log(stats);
  dispatch({ type: FETCH_STATS, payload: stats.data });
};

export const fetchJob = (id) => async (dispatch) => {
  const job = await api.get("job/" + id);
  dispatch({ type: FETCH_JOBS, payload: { list: [job.data], count: 1 } });
};
export const fetchJobs = (query, options) => async (dispatch) => {
  console.log({
    params: {
      match: { ...query, isAccepted: true },
      options,
    },
    sort: {},
  });
  const jobs = await api.get("jobs", {
    params: {
      match: { ...query, isAccepted: true },
      options,
    },
    sort: {},
  });
  //console.log(arrivals);
  dispatch({ type: FETCH_JOBS, payload: jobs.data });
};

export const fetchPendingCustomJobs =
  (options) => async (dispatch, getState) => {
    const { credentials } = getState().admin;
    const jobs = await adminApi(credentials).get("/moderation/jobs", {
      params: {
        options,
      },
    });
    console.log(jobs);
    dispatch({ type: FETCH_PENDING_CUSTOM_JOBS, payload: jobs.data });
  };

export const login =
  ({ remember, concatAuth }, silent) =>
  async (dispatch) => {
    try {
      const login = await api.post("/admin/login", null, {
        headers: { Authorization: `Basic ${concatAuth}` },
      });
      if (login.status === 200) {
        console.log(login);
        if (remember) {
          localStorage.setItem("admin", concatAuth);
        }
        console.log(login);
        dispatch({
          type: LOGIN,
          payload: {
            credentials: concatAuth,
            isAdmin: login.data.admin,
            object: login.data.object,
          },
        });
      } else throw new Error("Wrong credentials");
    } catch (error) {
      console.log("e", error);
      if (!silent) {
        if (error.status === 401) toastr.error("Wrong credentials!");
        else toastr.error(`${error.statusText}`);
      }
    }
  };

export const logout = () => async (dispatch) => {
  localStorage.removeItem("admin");
  dispatch({ type: LOGOUT });
};

export const fetchKpis = () => async (dispatch, getState) => {
  const { credentials } = getState().admin;
  const kpis = await adminApi(credentials).get("/admin/kpis");
  dispatch({ type: FETCH_KPIS, payload: kpis.data });
};

export const postNewJob = (job, history, postAgain) => async (dispatch) => {
  try {
    const result = await api.post("job", job);
    if (result.status === 200) {
      toastr.success(
        "Thank you for your contribution! Your job is being reviewed by your local FT Community. You will be notified of its status by email."
      );
      if (!postAgain) history.push("/jobs");
    } else throw new Error(`Failed with error ${result.status}`);
  } catch (error) {
    console.log(error);
    toastr.error(error.statusText);
  }
};

export const acceptCustomJob = (id, options) => async (dispatch, getState) => {
  try {
    const { credentials } = getState().admin;
    const result = await adminApi(credentials).put(`moderation/job/${id}`, {
      isAccepted: true,
      isRejected: false,
    });
    if (result.status === 200) {
      toastr.success("Post accepted succesfully!");
      dispatch(fetchPendingCustomJobs(options));
    } else throw new Error(`Failed with error ${result.status}`);
  } catch (error) {
    console.log("oops", error);
    toastr.error(error.statusText);
  }
};

export const rejectCustomJob = (id, options) => async (dispatch, getState) => {
  try {
    const { credentials } = getState().admin;
    const result = await adminApi(credentials).put(`moderation/job/${id}`, {
      isRejected: true,
      isAccepted: false,
    });
    if (result.status === 200) {
      toastr.success("Post rejected succesfully!");
      dispatch(fetchPendingCustomJobs(options));
    } else throw new Error(`Failed with error ${result.status}`);
  } catch (error) {
    toastr.error(error.statusText);
  }
};

export const hideCustomJob = (id, options) => async (dispatch, getState) => {
  try {
    const { credentials } = getState().admin;
    const result = await adminApi(credentials).put(`moderation/job/${id}`, {
      isHidden: true,
    });
    if (result.status === 200) {
      toastr.success("Post hidden succesfully!");
      dispatch(fetchPendingCustomJobs(options));
    } else throw new Error(`Failed with error ${result.status}`);
  } catch (error) {
    toastr.error(error.statusText);
  }
};

export const fetchCountries = () => async (dispatch) => {
  const countries = await api.get("countries");
  dispatch({ type: FETCH_COUNTRIES, payload: countries.data });
};

export const fetchJobsPerCompany = (l_id) => async (dispatch) => {
  const jobs = await api.get(`jobsPerCompany/${l_id}`);
  dispatch({ type: FETCH_JOBS_PER_COMPANY, payload: jobs.data });
};

export const fetchModerators = () => async (dispatch, getState) => {
  try {
    const { credentials } = getState().admin;
    const result = await adminApi(credentials).get("/admin/moderators");
    if (result.status === 200) {
      dispatch({ type: FETCH_MODS, payload: result.data });
    } else throw new Error("Wrong credentials");
  } catch (error) {
    console.log("e", error);
    if (error.status === 401) toastr.error("Wrong credentials!");
    else toastr.error(`${error.statusText}`);
  }
};

export const addModerator = (mod, clear) => async (dispatch, getState) => {
  try {
    const { community, name, email } = mod;
    if (!community || !name || !email)
      throw new Error("Please fill all the fields.");

    const { credentials } = getState().admin;
    const result = await adminApi(credentials).post("/admin/moderator", mod);
    if (result.status === 200) {
      dispatch({ type: CREATE_MOD, payload: result.data });
      toastr.success(
        "Account successfully created. The moderator has been notified by email."
      );
      clear();
    }
  } catch (error) {
    console.log("e", error);
    if (error.status === 401) toastr.error("Wrong credentials!");
    else toastr.error(`${error.data}`);
  }
};

export const deleteModerator = (id) => async (dispatch, getState) => {
  try {
    const { credentials } = getState().admin;
    const result = await adminApi(credentials).delete(`/admin/moderator/${id}`);
    if (result.status === 200) {
      dispatch({ type: DELETE_MOD, payload: id });
    } else throw new Error("Wrong credentials");
  } catch (error) {
    console.log("e", error);
    if (error.status === 401) toastr.error("Wrong credentials!");
    else toastr.error(`${error.statusText}`);
  }
};

export const fetchCommunities = () => async (dispatch, getState) => {
  try {
    const result = await api.get("/communities");
    if (result.status === 200) {
      dispatch({
        type: FETCH_COMMUNITIES,
        payload: result.data.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const communityA = a.community.toUpperCase();
          const communityB = b.community.toUpperCase();

          let comparison = 0;
          if (communityA > communityB) {
            comparison = 1;
          } else if (communityA < communityB) {
            comparison = -1;
          }
          return comparison;
        }),
      });
    }
  } catch (error) {
    console.log("e", error);
  }
};

export const hideAll = (type, options) => async (dispatch, getState) => {
  try {
    const { credentials } = getState().admin;
    const result = await adminApi(credentials).post("/moderation/hideAll", {
      type,
    });
    if (result.status === 200) {
      toastr.success("Done!");
      dispatch(fetchPendingCustomJobs(options));
    }
  } catch (error) {
    console.log("e", error);
    if (error.status === 401) toastr.error("Wrong credentials!");
    else toastr.error(`${error.data}`);
  }
};

export const createNotification = (notification) => async (dispatch) => {
  try {
    const result = await api.post("notification", notification);
    if (result.status === 200) {
      toastr.success(
        "You will be notified as soon as there are new jobs matching your query!"
      );
    } else throw new Error(`Failed with error ${result.status}`);
  } catch (error) {
    console.log(error);
    toastr.error(error.statusText);
  }
};

export const cancelNotification = (token) => async (dispatch) => {
  try {
    const result = await api.delete(`notification/${token}`);
    if (result.status === 200) {
      toastr.success("You won't receive notifications for this query anymore!");
    } else throw new Error(`Failed with error ${result.status}`);
  } catch (error) {
    console.log(error);
    toastr.error(error.statusText);
  }
};
