import React from "react";
import { Navbar } from "react-bootstrap";
import ScrollspyNav from "../utils/ScrollspyNav";
import JobBoardHeader from "./JobBoardHeader";
import Menu from "./Menu";
import "./Header.css";

class Header extends React.Component {
  renderNavItems() {
    return (
      <>
        <Navbar.Brand
          className="branding"
          href={this.props.noNav ? "/jobs" : "#home"}
        >
          Powered by{" "}
          <strong style={{ fontSize: 18, fontWeight: "bold" }}>
            La French Tech Taiwan
          </strong>
        </Navbar.Brand>
        <div className="menu_wrapper">{!this.props.noNav && <Menu />}</div>
      </>
    );
  }
  renderNavScroll() {
    if (!this.props.noNav) {
      return (
        <ScrollspyNav
          className="ScrollspyNav"
          scrollTargetIds={["home", "about", "job_board", "france", "next40"]}
          activeNavClass="active"
          offset={-120}
        >
          {this.renderNavItems()}
        </ScrollspyNav>
      );
    } else return this.renderNavItems();
  }
  render() {
    if (this.props.noNav) return <JobBoardHeader />;
    return (
      <div className="Header">
        <Navbar
          className="header_content"
          collapseOnSelect={true}
          expand="xl"
          variant="dark"
        >
          {this.renderNavScroll()}
        </Navbar>
      </div>
    );
  }
}

export default Header;
