import {combineReducers} from "redux";
import {reducer as toastrReducer} from "react-redux-toastr";
import {statsReducer} from "./statsReducer";
import {adminReducer} from "./adminReducer";
import jobsReducer from "./jobsReducer";
import customJobsReducer from "./customJobsReducer";
import kpisReducer from "./kpisReducer";
import countriesReducer from "./countriesReducer";
import jobsPerCompanyReducer from "./jobsPerCompanyReducer";
import modsReducer from "./modsReducer";
import communityReducer from "./communityReducer";

export default combineReducers({
  stats: statsReducer,
  toastr: toastrReducer,
  jobs: jobsReducer,
  pendingCustomJobs: customJobsReducer,
  admin: adminReducer,
  kpis: kpisReducer,
  countries: countriesReducer,
  jobsPerCompany: jobsPerCompanyReducer,
  moderators: modsReducer,
  communities: communityReducer
});
