import axios from "axios";
import config from "../config";

const api = axios.create({baseURL: config.api.baseURL});

api.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    return Promise.reject(error.response);
  }
);

export default api;

export const adminApi = auth => {
  api.defaults.headers.common["Authorization"] = `Basic ${auth}`;
  return api;
};
