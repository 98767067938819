import {FETCH_JOBS_PER_COMPANY} from "../actions/types";

export default (state = {}, {type, payload}) => {
  switch (type) {
    case FETCH_JOBS_PER_COMPANY:
      return {...state, ...payload};
    default:
      return state;
  }
};
