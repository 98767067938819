import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { fetchCommunities, postNewJob } from "../../actions";
import industries from "../../data/industries";
import { assembleDescription } from "../../utils";
import "./AddJobForm.css";
import Preview from "./Preview";

class AddJobForm extends React.Component {
  state = {
    step: 1,
    nameCompany: "",
    title: "",
    location: "",
    description_1: "",
    description_2: "",
    description_3: "",
    tags: "",
    link: "",
    name: "",
    email: "",
    community: "",
    type: "FT",
    industry: "",
    job_type: "",
    remote: false,
  };

  // state = {
  //   step: 2,
  //   nameCompany: "Blob",
  //   title:
  //     "This is a pretty long title for a job isnt it, This is a pretty long title for a job isnt it",
  //   location: "Test location",
  //   description_1:
  //     "This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! ",
  //   description_2:
  //     "This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! ",
  //   description_3:
  //     "This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! This is a long test description! ",
  //   tags: "hi, yo",
  //   link: "https://test.com",
  //   name: "QC",
  //   email: "qc@gmail.com",
  //   community: "5ed07fc8e377445685d4d3dd",
  //   type: "FT120",
  //   industry: "Agriculture",
  //   job_type: "internship",
  //   remote: true
  // };

  componentDidMount() {
    this.props.fetchCommunities();
  }

  onSubmitForm = async (event, postAgain) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const {
      step,
      name,
      title,
      location,
      description_1,
      description_2,
      description_3,
      tags,
      link,
      job_type,
      industry,
      remote,
      nameCompany,
      email,
      type,
      community,
    } = this.state;

    if (step === 1) this.setState({ step: 2 });
    else if (step === 2) this.setState({ step: 3 });
    else {
      const description = assembleDescription(
        description_1,
        description_2,
        description_3
      );

      const job = {
        company: {
          name: nameCompany,
          industry,
          sector: tags.split(","),
          category: type,
        },
        job: {
          title,
          location,
          type: job_type,
          remote,
          link,
          description,
          poster: { name, email },
          moderator: community,
        },
      };
      console.log(job);
      this.props.postNewJob(job, this.props.history, postAgain);
      if (postAgain) {
        this.setState({
          step: 2,
          nameCompany: "",
          title: "",
          location: "",
          description_1: "",
          description_2: "",
          description_3: "",
          tags: "",
          link: "",
          type: "FT",
          industry: "",
          job_type: "",
          remote: false,
        });
      }
    }
  };

  onHandleChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    console.log(e.target.name, e.target.value);
    this.setState({ state });
  };

  renderStep1() {
    return (
      <div>
        <p>
          Thank you for choosing La French Tech Jet, the free platform developed
          by La French Tech Taiwan. Our job board features thousands of job
          openings from La French Tech (FT) ecosystem and is promoted in many
          tech universities and talent pools worldwide. Submitted jobs will be
          reviewed by La FT Jet's crew (one moderator per FT Community) before
          being published on the Job Board for 30 days.
        </p>
        <p>
          Organizations below are welcome to post jobs on this platform:
          <ul>
            <li>
              French-based tech start-ups/scale-ups and their overseas branches
            </li>
            <li>
              Entities from official FT Communities worldwide:
              <ul>
                <li>French-founded tech start-ups/scale-ups</li>
                <li>
                  French corporate’s accelerators or Open Innovation platforms
                </li>
                <li>
                  Local tech start-up/scale-up with obvious ties to France, or
                  contributing to their FT community.
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <Form.Row>
          <Form.Group controlId="form_name">
            <Form.Label>Your Full Name</Form.Label>
            <Form.Control
              required
              className="form1_input"
              name="name"
              value={this.state.name}
              onChange={this.onHandleChange}
              type="text"
              minLength={2}
              placeholder="Please enter your name"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group controlId="form_email">
            <Form.Label>Your Email</Form.Label>
            <Form.Control
              required
              className="form1_input"
              name="email"
              value={this.state.email}
              onChange={this.onHandleChange}
              type="email"
              placeholder="Please enter your email"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group controlId="form_job_title">
            <Form.Label>Your French Tech Community*</Form.Label>
            <Form.Control
              as="select"
              className="form1_input"
              required
              name="community"
              value={this.state.community}
              onChange={this.onHandleChange}
            >
              <option value="">Choose...</option>
              {this.props.communities.map((community) => (
                <option key={community._id} value={community._id}>
                  {community.community}
                </option>
              ))}
            </Form.Control>
            <Form.Text>
              *If your FT Community is not in the list, please contact{" "}
              <a href="mailto:jobs@frenchtechtaiwan.com">
                La French Tech Taiwan
              </a>
            </Form.Text>
          </Form.Group>
        </Form.Row>
      </div>
    );
  }
  renderStep2() {
    return (
      <div>
        <Form.Row>
          <Col>
            <Form.Group controlId="form_company_name">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                required
                name="nameCompany"
                value={this.state.nameCompany}
                onChange={this.onHandleChange}
                type="text"
                minLength={3}
                placeholder="Enter company name"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="type">
              <Form.Label>Ecosystem</Form.Label>
              <Form.Control
                name="type"
                value={this.state.type}
                onChange={this.onHandleChange}
                as="select"
              >
                <option value="FT">La French Tech</option>
                <option value="next40">Next40</option>
                <option value="green20">Green20</option>
                <option value="FT120">FT120</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="form_job_title">
              <Form.Label>Industry</Form.Label>
              <Form.Control
                as="select"
                required
                name="industry"
                value={this.state.industry}
                onChange={this.onHandleChange}
              >
                <option value="">Select an industry</option>
                {industries.map((industry) => (
                  <option key={industry}>{industry}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col> </Col>
          <Col> </Col>
          <Col>
            <Form.Group controlId="form_company_tags">
              <Form.Label>Sector Tags</Form.Label>
              <Form.Control
                required
                name="tags"
                value={this.state.tags}
                onChange={this.onHandleChange}
                pattern="[A-Za-z, ]*"
                type="text"
                placeholder="IOT, Software"
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={4}>
            <Form.Group controlId="form_job_title">
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                required
                type="text"
                name="title"
                value={this.state.title}
                onChange={this.onHandleChange}
                minLength={3}
                placeholder="Enter the job title"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="form_job_location">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                required
                name="location"
                value={this.state.location}
                onChange={this.onHandleChange}
                pattern="[A-Za-z, ]*"
                placeholder="eg: Paris, France"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="form_job_type">
              <Form.Label>Job Type</Form.Label>
              <Form.Control
                as="select"
                required
                name="job_type"
                value={this.state.job_type}
                onChange={this.onHandleChange}
              >
                <option value="">Select one</option>
                <option value="fulltime">Full Time</option>
                <option value="parttime">Part Time</option>
                <option value="contract">Contract</option>
                <option value="internship">Internship / VIE</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Form.Check
              type="checkbox"
              name="remote"
              label="Fully remote job"
              checked={this.state.remote}
              onClick={() => {
                this.setState({ remote: !this.state.remote });
              }}
              style={{ textAlign: "center" }}
            />
          </Col>
        </Form.Row>
        <Form.Group controlId="form_job_description">
          <Form.Label>Job Description (English only)</Form.Label>
          <Form.Control
            required
            as="textarea"
            name="description_1"
            value={this.state.description_1}
            onChange={this.onHandleChange}
            minLength={100}
            rows="4"
            placeholder="Job Description"
            style={{ marginBottom: "10px" }}
          />
          <Form.Control
            required
            as="textarea"
            name="description_2"
            style={{ marginBottom: "10px" }}
            value={this.state.description_2}
            onChange={this.onHandleChange}
            minLength={100}
            placeholder="Job Requirements"
            rows="4"
          />
          <Form.Control
            required
            as="textarea"
            name="description_3"
            placeholder="Company Details"
            value={this.state.description_3}
            onChange={this.onHandleChange}
            minLength={100}
            rows="4"
          />
        </Form.Group>
        <Form.Row>
          <Col>
            <Form.Group controlId="form_job_link">
              <Form.Label>JOB APPLICATION LINK OR EMAIL ADDRESS</Form.Label>
              <Form.Control
                pattern="[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?"
                required
                name="link"
                value={this.state.link}
                onChange={this.onHandleChange}
                placeholder="LinkedIn, company website, etc."
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Form.Row>
      </div>
    );
  }
  renderFormContent() {
    if (this.state.step === 1) return this.renderStep1();
    else if (this.state.step === 2) return this.renderStep2();
    else {
      console.log(
        this.props.communities.find((e) => e._id === this.state.community)
          .community
      );
      return (
        <Preview
          community={
            this.props.communities.find((e) => e._id === this.state.community)
              .community
          }
          jobOffer={this.state}
        />
      );
    }
  }
  render() {
    console.log(this.state);
    return (
      <div id="job_form" className="content-wrapper">
        <div className="add_job_form content">
          <h3 className="title uppercase">Post a job offer</h3>
          <Form onSubmit={this.onSubmitForm}>
            {this.renderFormContent()}
            {(this.state.step === 2 || this.state.step === 3) && (
              <Button
                onClick={() => {
                  this.setState({ step: this.state.step - 1 });
                }}
                variant="dark"
                className="back_form colored_button_grey"
              >
                Back
              </Button>
            )}
            <Button
              type="submit"
              variant="dark"
              disabled={!this.props.communities && this.state.step === 1}
              className="next_form colored_button"
            >
              {this.state.step === 3 ? "Submit" : "Next"}
            </Button>
            {this.state.step === 3 && (
              <Button
                onClick={() => {
                  this.onSubmitForm(null, true);
                }}
                variant="dark"
                className="colored_button submit"
              >
                Submit and post again
              </Button>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { communities: state.communities };
};

export default withTranslation()(
  connect(mapStateToProps, { postNewJob, fetchCommunities })(AddJobForm)
);
