import React from "react";
import { Button } from "react-bootstrap";
import Kpi from "./Kpi";
import Review from "./Review";
import Moderators from "./Moderators";

class AdminContent extends React.Component {
  state = { currentMenu: "kpi" };
  onClickLogout = () => {
    this.props.logout();
  };

  renderAdminContent = () => {
    const { currentMenu } = this.state;
    switch (currentMenu) {
      case "kpi":
        return <Kpi />;
      case "review":
        return <Review admin />;
      case "moderators":
        return <Moderators />;
      default:
        return <Review />;
    }
  };

  renderModContent() {
    return <Review />;
  }

  render() {
    const { admin } = this.props;
    return (
      <div className="admin_panel">
        <h3 className="title uppercase">Administration Panel</h3>
        {!admin.isAdmin && (
          <p>
            {`Dear ${admin.object.name}, welcome to FT ${admin.object.community} moderation page.`}
          </p>
        )}
        <div className="admin_menu">
          {admin.isAdmin && (
            <>
              <Button
                className={`${
                  this.state.currentMenu === "kpi" ? "toggle-active" : "toggle"
                }`}
                onClick={() => this.setState({ currentMenu: "kpi" })}
                variant="dark"
              >
                Dashboard
              </Button>
              <Button
                onClick={() => this.setState({ currentMenu: "review" })}
                className={`${
                  this.state.currentMenu === "review"
                    ? "toggle-active"
                    : "toggle"
                }`}
                variant="dark"
              >
                Review
              </Button>
              <Button
                onClick={() => this.setState({ currentMenu: "moderators" })}
                className={`${
                  this.state.currentMenu === "moderators"
                    ? "toggle-active"
                    : "toggle"
                }`}
                variant="dark"
              >
                Moderators
              </Button>
            </>
          )}
        </div>
        <Button
          variant="dark"
          className="logout colored_button"
          onClick={this.onClickLogout}
        >
          Logout
        </Button>
        {admin.isAdmin ? this.renderAdminContent() : this.renderModContent()}
      </div>
    );
  }
}

export default AdminContent;
