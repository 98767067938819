export const FETCH_STATS = "FETCH_STATS";
export const FETCH_JOBS = "FETCH_JOBS";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FETCH_PENDING_CUSTOM_JOBS = "FETCH_PENDING_CUSTOM_JOBS";
export const REMOVE_JOB = "REMOVE_JOB";
export const FETCH_KPIS = "FETCH_KPIS";
export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_JOBS_PER_COMPANY = "FETCH_JOBS_PER_COMPANY";
export const FETCH_MODS = "FETCH_MODS";
export const DELETE_MOD = "DELETE_MOD";
export const CREATE_MOD = "CREATE_MOD";
export const FETCH_COMMUNITIES = "FETCH_COMMUNITIES";
