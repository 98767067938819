import {FETCH_PENDING_CUSTOM_JOBS, REMOVE_JOB} from "../actions/types";

export default (state = {}, {type, payload}) => {
  switch (type) {
    case FETCH_PENDING_CUSTOM_JOBS:
      return payload;
    case REMOVE_JOB:
      return {
        list: state.list.filter(job => job._id !== payload),
        count: state.count - 1
      };
    default:
      return state;
  }
};
