import React from "react";
import "./RoundedToggle.css";

class RoundedToggle extends React.Component {
  state = { currentTab: 0 };
  render() {
    const {
      leftTitle,
      rightTitle,
      onToggle,
      leftComponent,
      rightComponent
    } = this.props;
    const { currentTab } = this.state;
    return (
      <div className="rounded_toggle">
        <button
          title={leftTitle}
          className={`${
            currentTab === 0 ? "rounded-button-active" : "rounded-button"
          }`}
          onClick={() => {
            if (currentTab !== 0) onToggle(0);
            this.setState({ currentTab: 0 });
          }}
          variant="outline-secondary"
        >
          {leftComponent}
        </button>
        <button
          onClick={() => {
            if (currentTab !== 1) onToggle(1);
            this.setState({ currentTab: 1 });
          }}
          title={rightTitle}
          className={`${
            currentTab === 1 ? "rounded-button-active" : "rounded-button"
          }`}
          variant="outline-secondary"
        >
          {rightComponent}
        </button>
      </div>
    );
  }
}

export default RoundedToggle;
