import {LOGIN, LOGOUT} from "../actions/types";

export const adminReducer = (state = {loggedIn: false}, {type, payload}) => {
  switch (type) {
    case LOGIN:
      return {loggedIn: true, ...payload};
    case LOGOUT:
      return {loggedIn: false};
    default:
      return state;
  }
};
