import React from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "./Remote.css";

class Remote extends React.Component {
  render() {
    const { isRemote, changeRemote } = this.props;
    return (
      <div className="remote">
        <img
          src={`${process.env.PUBLIC_URL}/img/remote_green.svg`}
          alt="Remote"
          title="Remote"
          style={{ width: "20px", marginRight: "10px" }}
        />
        <span style={{ marginTop: "3px" }}>REMOTE</span>
        <Form.Check
          type="switch"
          style={{
            display: "inline-block",
            marginLeft: "10px"
          }}
          id="custom-switch"
          label=""
          color="green"
          checked={isRemote}
          onChange={changeRemote}
        />
      </div>
    );
  }
}

export default withTranslation()(Remote);
