import React from "react";
import { Row, Col } from "react-bootstrap";
import Panel from "../jobBoard/Panel";
import { assembleDescription } from "../../utils";

class Preview extends React.Component {
  render() {
    const { community } = this.props;
    const {
      nameCompany,
      title,
      location,
      description_1,
      description_2,
      description_3,
      tags,
      link,
      name,
      email,
      type,
      industry,
      job_type,
      remote
    } = this.props.jobOffer;
    const description = assembleDescription(
      description_1,
      description_2,
      description_3
    );
    const job = {
      company: {
        name: nameCompany,
        industry,
        sector: tags.split(","),
        category: type
      },
      title,
      location,
      type: job_type,
      remote,
      link,
      description,
      poster: { name, email },
      moderator: community,
      date: new Date(Date.now()).toISOString()
    };
    return (
      <div className="preview">
        <h2>Summary & Preview</h2>
        <Row>
          <Col>
            <h4>NAME:</h4>
            <span>{name}</span>
          </Col>
          <Col>
            <h4>EMAIL:</h4>
            <span>{email}</span>
          </Col>
          <Col>
            <h4>FRENCH TECH COMMUNITY:</h4>
            <span>{community}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>IT’S A FULLY REMOTE JOB:</h4>
            <span>{remote ? "Yes" : "No"}</span>
          </Col>

          <Col></Col>
          <Col>
            <h4>SECTOR TAGS (not displayed but searchable):</h4>
            <span>{tags}</span>
          </Col>
        </Row>
        <Panel preview jobs={[job]} />
      </div>
    );
  }
}

export default Preview;
