import queryString from "query-string";

export const stringify = object => {
  const stringifiedState = {};
  for (const key of Object.keys(object)) {
    const value = object[key];
    stringifiedState[key] =
      typeof value === "object" ? JSON.stringify(value) : value;
  }
  return queryString.stringify(stringifiedState);
};

export const parse = search => {
  const parsedSearch = queryString.parse(search);
  const parsedResult = {};
  for (const key of Object.keys(parsedSearch)) {
    const value = parsedSearch[key];
    parsedResult[key] = isJson(value) ? JSON.parse(value) : value;
  }
  return parsedResult;
};

const isJson = item => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
};
