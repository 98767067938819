import React from "react";
import { FaPlaneArrival, FaPlaneDeparture } from "react-icons/fa";
import { withTranslation } from "react-i18next";
import RoundedToggle from "./RoundedToggle";
class DestinationButton extends React.Component {
  render() {
    const { t, changeTab } = this.props;
    return (
      <div className="country_tab">
        <RoundedToggle
          leftTitle={t("jobBoard.switch.france")}
          rightTitle={t("jobBoard.switch.international")}
          onToggle={status => {
            if (status === 0) changeTab("France");
            else changeTab("International");
          }}
          leftComponent={
            <>
              <FaPlaneArrival /> FRANCE
            </>
          }
          rightComponent={
            <>
              <FaPlaneDeparture /> INTERNATIONAL
            </>
          }
        />
      </div>
    );
  }
}

export default withTranslation()(DestinationButton);
