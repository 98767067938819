import React from "react";
import { Table, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { sanitizeUrl } from "../../utils";
import "./Panel.css";

class Panel extends React.Component {
  state = { toggle: null };
  onClickJobOffer = job => {
    let jobUrl = sanitizeUrl(job.link);
    if (jobUrl.startsWith("mailto:")) {
      jobUrl += `?subject=La French Tech Jet:  My application to ${job.title}`;
    }
    var win = window.open(jobUrl, "_blank");
    win.focus();
  };
  renderCompanyType(job) {
    const { category } = job.company;
    const company_types = {
      FT: { title: "French Tech local job", cssClass: "ft" },
      next40: { title: "Next 40", cssClass: "next40" }
    };
    const { cssClass, title } = company_types[category];
    return <div className={`company_type ${cssClass}`} title={title}></div>;
  }
  renderJobPoster = job => {
    console.log(job);
    if (!job.poster) return;
    const { name, email } = job.poster;
    return <td className="td-12">{`${name} (${email})`}</td>;
  };
  renderStatus = job => {
    const expireLimit = Date.now() - 30 * 24 * 3600 * 1000;
    if (new Date(job.date).getTime() < expireLimit) {
      return <span className="yellow">Expired</span>;
    } else if (job.isAccepted) return <span className="green">Published</span>;
    else if (job.isRejected) return <span className="red">Rejected</span>;
    else return <span>Pending</span>;
  };
  renderJobs() {
    const { toggle } = this.state;
    if (!this.props.jobs || !this.props.jobs.length) {
      return (
        <tr>
          <td>There are currently no jobs matching this request!</td>
        </tr>
      );
    } else {
      return this.props.jobs.map(job => (
        <React.Fragment key={job._id}>
          <tr className="clickableTr">
            <td>
              {job.date
                .split("T")[0]
                .replace(/-/g, "/")
                .split("/")
                .splice(1)
                .join("/")}
            </td>
            <td>{job.company.industry}</td>
            <td>{job.company.name}</td>
            <td>{job.location}</td>
            <td>{job.title}</td>

            {this.renderJobPoster(job)}
            <td>{this.renderStatus(job)}</td>
            <td className="admin_buttons">
              <span
                onClick={() => {
                  this.setState({
                    toggle: toggle === job._id ? null : job._id
                  });
                }}
              >
                {toggle !== job._id ? "View" : "Collapse"}
              </span>
              {this.props.renderActions(job)}
            </td>
          </tr>
          <tr
            style={{
              display: toggle === job._id ? "table-row" : "none"
            }}
          >
            <td colSpan={6}>
              <div dangerouslySetInnerHTML={{ __html: job.description }}></div>
              <Button id="checkin" onClick={() => this.onClickJobOffer(job)}>
                CHECK-IN
              </Button>
            </td>
          </tr>
        </React.Fragment>
      ));
    }
  }

  renderPaginate() {
    if (!this.props.pages) return null;
    return (
      <div className="pagination_wrapper">
        <ReactPaginate
          pageCount={this.props.pages || 1}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          forcePage={this.props.page}
          breakClassName={"break-me"}
          onPageChange={this.props.onPageChange}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    );
  }
  render() {
    return (
      <div className={`panel review ${this.props.className}`}>
        <h4 className="bold uppercase title">{this.props.title}</h4>
        {this.props.renderHeaderActions && this.props.renderHeaderActions()}
        <Table responsive className="white-table">
          <thead className="titles">
            <tr>
              <th>Time</th>
              <th>Terminal</th>
              <th>Flight</th>
              <th>Destination</th>
              <th>Remark</th>
              <th>Published by</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderJobs()}</tbody>
        </Table>
        {this.renderPaginate()}
      </div>
    );
  }
}

export default Panel;
