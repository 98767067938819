import React from "react";
import { Button, Table } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import ReactPaginate from "react-paginate";
import { toastr } from "react-redux-toastr";
import api from "../../api";
import {
  beautifyJobType,
  capitalize,
  sanitizeJobDescription,
  sanitizeUrl,
} from "../../utils";
import GA from "../utils/GA";
import "./Panel.css";

class Panel extends React.Component {
  state = { toggle: null };
  componentDidMount() {
    this.setState({ toggle: this.props.singleId });

    setTimeout(() => {
      console.log(this.state);
    }, 1000);
  }
  componentDidUpdate() {
    if (this.props.jobs && this.props.singleId) {
      const job = this.props.jobs[0];
      console.log("sending");
      GA.event({
        category: "Read Job Description",
        action: job.company.name,
        label: `${job.title} (${job.location})`,
      });
    }
  }
  renderCompanyType(job) {
    const { category } = job.company;
    return (
      <img
        className="company_type"
        alt={category}
        src={
          category === "green20"
            ? `${process.env.PUBLIC_URL}/Green20.jpeg`
            : `${process.env.PUBLIC_URL}/${category}_icon.svg`
        }
      />
    );
  }
  onClickJobOffer = async (job) => {
    api.post(`view`, { job: job._id });
    let jobUrl = sanitizeUrl(job.link);
    if (jobUrl.startsWith("mailto:")) {
      jobUrl += `?subject=La French Tech Jet:  My application to ${job.title}`;
    }
    var win = window.open(jobUrl, "_blank");
    win.focus();
  };

  renderJobs() {
    const { toggle } = this.state;
    if (!this.props.jobs || !this.props.jobs.length) {
      return (
        <tbody>
          <tr>
            <td>There are currently no jobs matching this request!</td>
          </tr>
        </tbody>
      );
    } else {
      return (
        <tbody>
          {this.props.jobs.map((job) => {
            return (
              <React.Fragment key={job._id}>
                <tr
                  className="clickableTr"
                  onClick={() => {
                    this.setState({
                      toggle: toggle === job._id ? null : job._id,
                    });
                    if (toggle !== job._id) {
                      GA.event({
                        category: "Read Job Description",
                        action: job.company.name,
                        label: `${job.title} (${job.location})`,
                      });
                    }
                  }}
                >
                  <td className="time">
                    {job.date
                      .split("T")[0]
                      .replace(/-/g, "/")
                      .split("/")
                      .splice(1)
                      .join("/")}
                  </td>
                  <td className="flight capital">
                    {this.renderCompanyType(job)}
                    {job.company.name}
                  </td>
                  <td className="location capital">{job.location}</td>
                  <td className="remark capitalize">
                    {capitalize(job.title.replace(/\([^()]*\)/g, ""))}
                  </td>
                  <td className={"terminal capital"}>{job.company.industry}</td>

                  <td className="gate">
                    {job.type ? beautifyJobType(job.type) : ""}
                    {job.remote && (
                      <img
                        src={`${process.env.PUBLIC_URL}/img/remote_green.svg`}
                        alt="Remote"
                        title="Remote"
                        style={{ width: "20px", marginLeft: "10px" }}
                      />
                    )}
                  </td>
                </tr>
                <tr
                  style={{
                    display: toggle === job._id ? "table-row" : "none",
                  }}
                >
                  <td colSpan={6}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizeJobDescription(job.description),
                      }}
                    ></div>
                    <Button
                      id="checkin"
                      onClick={() => this.onClickJobOffer(job)}
                    >
                      CHECK-IN
                    </Button>
                    <Button
                      style={{ marginLeft: 30 }}
                      id="permlink"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          process.env.REACT_APP_BASE_URL +
                            "/jobs/view/" +
                            job._id
                        );
                        toastr.success(
                          "This post permlink has been copied to your clipboard!"
                        );
                      }}
                    >
                      SHARE THIS JOB
                    </Button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      );
    }
  }
  renderSortIcon(name) {
    if (this.props.sortState[name] === -1) {
      return (
        <TiArrowSortedDown
          className="sort"
          onClick={() => this.props.onSort(name, 1)}
        />
      );
    } else if (this.props.sortState[name] === 1) {
      return (
        <TiArrowSortedUp
          className="sort"
          onClick={() => this.props.onSort(name, -1)}
        />
      );
    } else if (!this.props.sortState[name]) {
      return (
        <TiArrowUnsorted
          className="sort"
          onClick={() => this.props.onSort(name, name === "date" ? -1 : 1)}
        />
      );
    }
    return;
  }
  renderPaginate() {
    if (!this.props.pages || this.props.singleId) return null;
    return (
      <div className="pagination_wrapper">
        <ReactPaginate
          pageCount={this.props.pages || 1}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          forcePage={this.props.page}
          breakClassName={"break-me"}
          onPageChange={this.props.onPageChange}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    );
  }
  render() {
    const { t, preview } = this.props;
    return (
      <div className="panel">
        <h3 className="bold uppercase title">{this.props.title}</h3>
        <Table className="white-table" responsive>
          <thead>
            <tr className="bold capital">
              <th className="time">
                {t("jobBoard.heads.date")}
                {!preview && this.renderSortIcon("date")}
              </th>
              <th className="flight">
                {t("jobBoard.heads.flight")}
                {!preview && this.renderSortIcon("company.name")}
              </th>
              <th className="location">
                {t("jobBoard.heads.destination")}
                {!preview && this.renderSortIcon("location")}
              </th>
              <th className="remark">
                {t("jobBoard.heads.remark")}
                {!preview && this.renderSortIcon("title")}
              </th>
              <th className="terminal">
                {t("jobBoard.heads.terminal")}
                {!preview && this.renderSortIcon("company.industry")}
              </th>
              <th className="gate">
                {t("jobBoard.heads.gate")}
                {!preview && this.renderSortIcon("type")}
              </th>
            </tr>
          </thead>
          {this.renderJobs()}
        </Table>
        {!preview && this.renderPaginate()}
      </div>
    );
  }
}

export default withTranslation()(Panel);
