import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import AdminContent from "./AdminContent";
import AdminLogin from "./AdminLogin";
import { login, logout } from "../../actions";
import "./Admin.css";

class Admin extends React.Component {
  componentDidMount() {
    const concatAuth = localStorage.getItem("admin");
    if (concatAuth) {
      this.props.login({ concatAuth }, true);
    }
  }
  render() {
    return (
      <div className="content-wrapper" id="admin">
        <div className="admin content">
          <Route
            render={props =>
              this.props.admin.loggedIn ? (
                <AdminContent
                  admin={this.props.admin}
                  logout={this.props.logout}
                />
              ) : (
                <AdminLogin login={this.props.login} />
              )
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { admin: state.admin };
};

export default connect(mapStateToProps, { login, logout })(Admin);
