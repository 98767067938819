import React from "react";
import { connect } from "react-redux";
import {
  fetchPendingCustomJobs,
  acceptCustomJob,
  rejectCustomJob,
  hideCustomJob,
  hideAll
} from "../../actions";
import Panel from "./Panel";
import config from "../../config";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

class Review extends React.Component {
  state = {
    options: {
      sort: { date: -1 },
      limit: config.JOBS_PER_PAGE,
      skip: 0
    }
  };
  componentDidMount() {
    this.props.fetchPendingCustomJobs(this.state.options);
  }

  onPageChange = selected => {
    const options = this.state.options;
    options.skip = selected * config.JOBS_PER_PAGE;
    this.props.fetchPendingCustomJobs(this.state.query, options);
    this.setState(options);
  };
  renderActionsWQ = job => {
    const { options } = this.state;
    return (
      <>
        <span
          onClick={() => {
            this.props.acceptCustomJob(job._id, options);
          }}
        >
          Publish
        </span>
        <span
          onClick={() => {
            this.props.rejectCustomJob(job._id, options);
          }}
        >
          Reject
        </span>
      </>
    );
  };

  renderActionsProcessed = job => {
    const { options } = this.state;
    const expireLimit = Date.now() - 30 * 24 * 3600 * 1000;
    if (new Date(job.date).getTime() < expireLimit) {
      return (
        <span
          onClick={() => {
            confirmAlert({
              title: "Hide job posting",
              message: "This cannot be undone. Proceed?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    this.props.hideCustomJob(job._id, options);
                  }
                },
                {
                  label: "No",
                  onClick: () => {}
                }
              ]
            });
          }}
        >
          Hide
        </span>
      );
    }
    if (job.isAccepted) {
      return (
        <span
          onClick={() => {
            this.props.rejectCustomJob(job._id, options);
          }}
        >
          Remove
        </span>
      );
    } else if (job.isRejected) {
      return (
        <>
          <span
            onClick={() => {
              this.props.acceptCustomJob(job._id, options);
            }}
          >
            Publish
          </span>
          <span
            onClick={() => {
              confirmAlert({
                title: "Hide job posting",
                message: "This cannot be undone. Proceed?",
                buttons: [
                  {
                    label: "Yes",
                    onClick: () => {
                      this.props.hideCustomJob(job._id, options);
                    }
                  },
                  {
                    label: "No",
                    onClick: () => {}
                  }
                ]
              });
            }}
          >
            Hide
          </span>
        </>
      );
    }
  };

  renderHeaderActions = () => {
    const { hideAll, admin } = this.props;
    const { options } = this.state;
    if (admin) return;
    return (
      <span className="float_right">
        <span
          className="hide_all hide_all_rejected"
          onClick={() => {
            confirmAlert({
              title: "Hide all rejected job postings",
              message: "This cannot be undone. Proceed?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    hideAll("rejected", options);
                  }
                },
                {
                  label: "No",
                  onClick: () => {}
                }
              ]
            });
          }}
        >
          Hide all rejected jobs
        </span>
        <span
          className="hide_all"
          onClick={() => {
            confirmAlert({
              title: "Hide all expired job postings",
              message: "This cannot be undone. Proceed?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    hideAll("expired", options);
                  }
                },
                {
                  label: "No",
                  onClick: () => {}
                }
              ]
            });
          }}
        >
          Hide all expired jobs
        </span>
      </span>
    );
  };

  render() {
    const { jobs } = this.props;
    if (!jobs || (!jobs.pending && !jobs.processed)) return "Nothing to review";
    else {
      return (
        <>
          <h3>Guidelines</h3>
          <br />
          The moderator has a responsibility to ensure that La French Tech Jet
          Job board stays mainly focused on the right targets (startups, tech
          jobs…) and scope (La French Tech ecosystem). This will help maintain
          its quality, hence good traffic. Below are some general guidelines to
          help you moderate the jobs posted by your community.
          <br /> <br />
          <strong>Which companies are eligible to post jobs:</strong>
          <ul>
            <li>
              Any French-based tech start-ups/scale-ups and their international
              branches or local representatives
            </li>
            <li>
              Organisations from your FT Community
              <ul>
                <li>
                  French-founded tech startups/scale-ups (at least one French
                  cofounder)
                </li>
                <li>
                  Tech start-up, scale-up, and partners with a strong French
                  flavor (French Executive, CEO, main investor, etc.; has a
                  branch in France, is a member of the French Chamber, etc.)
                </li>
                <li>
                  Corporate Open Innovation / start-up programs (e.g. Orange
                  Fab, AXA innovation lab…)
                </li>
                <li>
                  Local tech startups or scale-up (no French Flavor) offering
                  some reciprocity to your community (e.g. offering free
                  services or resources to your local FT startups) AND offering
                  tech jobs.
                </li>
              </ul>
            </li>
          </ul>
          <strong>Who shouldn’t post</strong>
          <ul>
            <li>
              Large Corporations (exception from their Open innovation/Startups
              programs…)
            </li>
            <li>
              Foreign companies with no connexion to France or La French Tech
            </li>
            <li>Startups from non-moderated FT Communities (so far).</li>
          </ul>
          <br /> <br />
          <i>
            Note: La French Tech Taiwan reserves the right to unpublish
            irrelevant jobs submitted by your community if necessary (we will
            always consult with you first).
          </i>
          <br />
          <br />
          We encourage you to visit La French Tech Jet{" "}
          <a href="https://job.ftnext40.com/faq">FAQ page</a>, and if you still
          have questions or simply need help, don’t hesitate to contact us.
          <br />
          <br />
          <Panel
            className="departures"
            title="Waiting Queue"
            jobs={jobs.pending.list}
            admin
            page={this.state.options.skip / config.JOBS_PER_PAGE}
            pages={Math.ceil(jobs.pending.count / config.JOBS_PER_PAGE)}
            onPageChange={({ selected }) => {
              this.onPageChange(selected);
            }}
            renderActions={this.renderActionsWQ}
          />
          <Panel
            className="processed_panel"
            title="Processed"
            jobs={jobs.processed.list}
            admin
            page={this.state.options.skip / config.JOBS_PER_PAGE}
            pages={Math.ceil(jobs.processed.count / config.JOBS_PER_PAGE)}
            onPageChange={({ selected }) => {
              this.onPageChange(selected);
            }}
            renderActions={this.renderActionsProcessed}
            renderHeaderActions={this.renderHeaderActions}
          />
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  console.log(state);
  return { jobs: state.pendingCustomJobs };
};

export default connect(mapStateToProps, {
  fetchPendingCustomJobs,
  acceptCustomJob,
  rejectCustomJob,
  hideCustomJob,
  hideAll
})(Review);
