import React from "react";
import "./Header.css";

class Header extends React.Component {
  render() {
    return (
      <div className="Header Header_black">
        <div className="header_content">
          <div id="header_left">
            <span id="header_job_board">
              <a href="/jobs">Jobs @La French Tech</a>
            </span>
            <span id="lftjet"></span>
          </div>
          <div id="header_right">
            <span id="powered_ft">Powered by La French Tech Taiwan</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
