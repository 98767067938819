import React from "react";
import "./Iframe.css";

function Iframe(props) {
  return (
    <div class="container">
      <iframe
        class="responsive-iframe"
        src="https://www.frenchtechtaiwan.com/jobs-la-french-tech/"
      ></iframe>
    </div>
  );
}

export default Iframe;
