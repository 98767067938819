import React from "react";
import {withTranslation} from "react-i18next";
import "./Others.css";

function Credits(props) {
  const {t} = props;
  return (
    <div id="credits" className="content">
      <h1>{t("footer.credits")}</h1>
      <div dangerouslySetInnerHTML={{__html: t("credits.text")}}></div>
    </div>
  );
}

export default withTranslation()(Credits);
