import React from "react";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { cancelNotification, fetchJob, fetchJobs } from "../../actions";
import config from "../../config";
import * as urlParser from "../utils/urlParser";
import "./JobBoard.css";
import Panel from "./Panel";
import DestinationButton from "./search/DestinationButton";
import Notifications from "./search/Notifications";
import Remote from "./search/Remote";
import SearchBar from "./search/SearchBar";

class Content extends React.Component {
  state = {
    currentTab: "France",
    query: {
      country: { $regex: "france", $options: "i" },
    },
    options: {
      sort: { date: -1 },
      limit: config.JOBS_PER_PAGE,
      skip: 0,
    },
    industry: "all",
    keywords: "",
  };
  componentDidMount() {
    const search = urlParser.parse(this.props.location.search);
    if (search.cancel_notifications) {
      this.props.cancelNotification(search.cancel_notifications);
    }
    const state = { ...this.state, ...search };
    const id = this.props.match.params.id;
    if (id) this.props.fetchJob(id);
    else {
      this.props.fetchJobs(state.query, state.options);
      this.setState(state);
    }
  }

  sort(name, val) {
    const sort = {};
    sort[name] = val;
    let state;
    state = {
      options: {
        ...this.state.options,
        sort,
        skip: 0,
      },
    };

    this.setState(state);
    return state.options;
  }
  onClickNotif = () => {
    const { state } = this;
    console.log(state);
    const stringified = urlParser.stringify(state);
    console.log(stringified);
  };
  onSort = (name, val) => {
    const options = this.sort(name, val);
    this.props.fetchJobs(this.state.query, options);
  };

  onPageChange = (selected) => {
    const options = this.state.options;
    options.skip = selected * config.JOBS_PER_PAGE;
    this.props.fetchJobs(this.state.query, options);
    this.setState(options);
  };

  onQuery = (newQuery) => {
    let { options, query, keywords, industry } = this.state;
    query = { ...query, ...newQuery };
    options.skip = 0;
    if (!keywords.length) {
      delete query.$text;
    }
    if (industry === "all") {
      delete query["company.industry"];
    }
    this.props.fetchJobs(query, options);
    this.setState({ query, options });
  };
  changeTab = (tab) => {
    const state = this.state;
    state.options.skip = 0;
    state.currentTab = tab;
    if (tab === "France") {
      state.query.country = { $regex: "France", $options: "i" };
    } else
      state.query.country = { $regex: "^((?!France).)*$", $options: "mgi" };
    const { query, options } = state;
    this.setState(state);
    this.props.fetchJobs(query, options);
  };
  changeRemote = () => {
    console.log(this.state);
    const { query, options } = this.state;
    if (!query.remote) {
      query.remote = true;
    } else {
      delete query.remote;
    }
    this.setState({ query });
    this.props.fetchJobs(query, options);
  };
  renderJobCount = () => {
    const { t } = this.props;
    if (this.props.jobs.count || this.props.jobs.count === 0)
      return (
        <div className="results_nb">
          <span>{`${t("jobBoard.search_bar.results")}: ${
            this.props.jobs.count
          }`}</span>
        </div>
      );
    else return null;
  };
  onHandleChange = (e) => {
    const { state } = this;
    state[e.target.name] = e.target.value;
    this.setState({ state });
  };
  renderFilters() {
    const { keywords, industry } = this.state;
    const id = this.props.match.params.id;

    if (!id) {
      return (
        <div className="filters-wrapper">
          <div className="filters">
            <SearchBar
              industry={industry}
              keywords={keywords}
              onQuery={this.onQuery}
              onHandleChange={this.onHandleChange}
            />
            <div className="subfilter">
              <DestinationButton
                currentTab={this.state.currentTab}
                changeTab={this.changeTab}
              />
              <Remote
                isRemote={this.state.query.remote ? true : false}
                changeRemote={this.changeRemote}
              />
              {this.renderJobCount()}
              <Notifications
                state={urlParser.stringify(this.state)}
                query={JSON.stringify(this.state.query)}
              />
            </div>
          </div>

          <div id="post_job_wrapper">
            <Button
              id="post_job"
              onClick={() => window.open("/jobs/new", "_blank")}
            >
              Employers | Post a job
            </Button>
          </div>
        </div>
      );
    } else
      return (
        <Button id="post_job" onClick={() => (window.location = "/jobs")}>
          See all jobs
        </Button>
      );
  }
  render() {
    const { t } = this.props;
    return (
      <div className="content-wrapper" id="job_board_real">
        <div className="content">
          {this.renderFilters()}
          <div className="panels">
            <Panel
              history={this.props.history}
              className="departures"
              jobs={this.props.jobs.list}
              onSort={this.onSort}
              sortState={this.state.options.sort}
              page={this.state.options.skip / config.JOBS_PER_PAGE}
              singleId={this.props.match.params.id}
              pages={Math.ceil(this.props.jobs.count / config.JOBS_PER_PAGE)}
              onPageChange={({ selected }) => {
                this.onPageChange(selected);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return { jobs: state.jobs };
};

export default withTranslation()(
  connect(mapStateToProps, { fetchJobs, cancelNotification, fetchJob })(Content)
);
