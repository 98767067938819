export default [
  "Agriculture",
  "Automobile & components",
  "Finance & Insurance",
  "Construction",
  "Defense & Aerospace",
  "Education",
  "Energy & Environment",
  "Food & Beverage",
  "Healthcare",
  "Hospitality",
  "ICT & Hardware",
  "Materials & Chemistry",
  "Media & Entertainment",
  "Pharma & Biotech",
  "Real Estate",
  "Retail & Consumer Services",
  "Services & Software",
  "Telecommunication",
  "Transportation"
];
