import {FETCH_STATS} from "../actions/types";

export const statsReducer = (state = {}, {type, payload}) => {
  switch (type) {
    case FETCH_STATS:
      return {...state, ...payload};
    default:
      return state;
  }
};
