import React from "react";
import { OverlayTrigger, Popover, Form, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { createNotification } from "../../../actions";
import { connect } from "react-redux";
import "./Notifications.css";

class Notifications extends React.Component {
  state = { email: "", name: "" };
  onSubmitNotif = event => {
    const { createNotification, query, state } = this.props;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    createNotification({ ...this.state, query, state });
    document.body.click();
  };
  renderPopover = props => {
    const { name, email } = this.state;
    return (
      <Popover {...props} className="popover" id="popover-basic">
        <Popover.Title as="h3">Alert</Popover.Title>
        <Popover.Content>
          Hi! You can create email alerts with your current active search and
          receive daily notifications.
          <br />
          <Form className="form" onSubmit={this.onSubmitNotif}>
            <Form.Group controlId="formBasicName">
              <Form.Label>Alert Name</Form.Label>
              <Form.Control
                minLength={4}
                type="text"
                value={name}
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
                required
                placeholder="Enter alert name"
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                value={email}
                onChange={e => {
                  this.setState({ email: e.target.value });
                }}
                required
                type="email"
                placeholder="Enter email"
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                style={{ borderColor: "unset" }}
                className="colored_button"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </Popover.Content>
      </Popover>
    );
  };
  render() {
    //const {t} = this.props;
    return (
      <div className="notifications">
        <span>Job alert</span>
        <OverlayTrigger
          trigger="click"
          rootClose={true}
          placement="top"
          className="button_notif_trigger"
          overlay={this.renderPopover}
        >
          <div className="button_notif">
            <img
              src={`${process.env.PUBLIC_URL}/img/notification.svg`}
              alt=""
              title="Subscribe to this search"
              style={{ width: "18px", height: "18px", marginRight: "10px" }}
            />
          </div>
        </OverlayTrigger>
      </div>
    );
  }
}

export default connect(null, { createNotification })(
  withTranslation()(Notifications)
);
