export const beautifyJobType = (type) => {
  switch (type) {
    case "fulltime":
      return "Full time";
    case "parttime":
      return "Part-time";
    case "Internship":
      return "Internship / VIE";
    default:
      return capitalize(type);
  }
};

export const capitalize = (string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const sanitizeJobDescription = (string) => {
  return string.replace(/<button(.|\n)*?<\/button>/g, "");
};

export const sanitizeUrl = (string) => {
  const regex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  const isEmail = regex.test(string);
  return isEmail ? `mailto:${string}` : string;
};

export const assembleDescription = (
  description_1,
  description_2,
  description_3
) => {
  return `<h5>Job Description</h5>
  <span>${description_1.replace("\n", "<br/><br/>")}</span>
  <h5>Job Requirements</h5>
  <span>${description_2.replace("\n", "<br/><br/>")}</span>
  <h5>Company Details</h5>
  <span>${description_3.replace("\n", "<br/><br/>")}</span>`;
};
