import React from "react";
import {Navbar, Nav} from "react-bootstrap";
import {withTranslation} from "react-i18next";

class Menu extends React.Component {
  render() {
    const {t} = this.props;
    return (
      <div className="menu">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#about">
              <img
                src={`${process.env.PUBLIC_URL}/Icons/about.png`}
                alt={t("menu.about")}
              />
              <span className="link_title">{t("menu.about")}</span>
            </Nav.Link>
            <Nav.Link href="#job_board">
              <img
                src={`${process.env.PUBLIC_URL}/Icons/job board.png`}
                alt={t("menu.jobBoard")}
              />
              <span className="link_title">{t("menu.jobBoard")}</span>
            </Nav.Link>
            <Nav.Link href="#france">
              <img
                src={`${process.env.PUBLIC_URL}/Icons/france.png`}
                alt={t("menu.france")}
              />
              <span className="link_title">{t("menu.france")}</span>
            </Nav.Link>
            <Nav.Link href="#next40">
              <img
                src={`${process.env.PUBLIC_URL}/Icons/40.png`}
                alt={t("menu.Next40")}
              />
              <span className="link_title">{t("menu.next40")}</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    );
  }
}

export default withTranslation()(Menu);
