import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { BrowserRouter, Route, withRouter } from "react-router-dom";
import Admin from "./admin/Admin";
import "./App.css";
import AddJobForm from "./arrivals/AddJobForm";
import JobBoardFooter from "./footer/JobBoardFooter";
import Header from "./header/Header";
import JobBoard from "./jobBoard/JobBoard";
import Credits from "./others/Credits";
import Faq from "./others/Faq";
import Iframe from "./others/Iframe";
import ReactGA from "./utils/GA";

const RouterListenerComponent = ({ history }) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    history.listen(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    });
  }, [history]);
  return <></>;
};
const RouterListener = withRouter(RouterListenerComponent);

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <RouterListener />
        {/* <Route path="/" exact component={Home} />
        <Route path="/" exact component={About} />
        <Route path="/" exact component={FakeJobBoard} />
        <Route path="/" exact component={France} />
        <Route path="/" exact component={Next40} />
        <Route path="/" exact component={Green20} />
        <Route path="/" exact component={Footer} /> */}
        <Route path="/" exact component={Iframe} />
        <Route
          path="/(jobs|faq|credits)"
          render={(props) => <Header {...props} noNav />}
        />
        <Route path="/jobs/admin" exact component={Admin} />
        <Route path="/jobs" exact component={JobBoard} />
        <Route path="/jobs/view/:id" component={JobBoard} />
        <Route path="/faq" exact component={Faq} />
        <Route path="/credits" exact component={Credits} />
        <Route path="/jobs/new" exact component={AddJobForm} />
        <Route path="/(jobs|faq|credits)" component={JobBoardFooter} />
      </BrowserRouter>
      <ReduxToastr
        timeOut={6000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-right"
        transitionIn="bounceIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </div>
  );
};

export default withTranslation()(App);
