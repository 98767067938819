import React from "react";
import {connect} from "react-redux";
import {fetchModerators, addModerator, deleteModerator} from "../../actions";
import {Table} from "react-bootstrap";

class Moderators extends React.Component {
  state = {
    name: "",
    community: "",
    email: ""
  };
  componentDidMount() {
    this.props.fetchModerators();
  }
  clear = () => {
    this.setState({name: "", community: "", email: ""});
  };
  onHandleChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({state});
  };
  renderAggregatedMods() {
    const {moderators, deleteModerator} = this.props;
    if (!moderators.length) return;
    else
      return moderators.map(moderator => {
        return (
          <tr key={moderator._id}>
            <td>{moderator.created_at}</td>
            <td>{moderator.community}</td>
            <td>{moderator.name}</td>
            <td>{moderator.email}</td>
            <td>
              <span
                className="action"
                onClick={() => {
                  deleteModerator(moderator._id);
                }}
              >
                Delete
              </span>
            </td>
          </tr>
        );
      });
  }
  render() {
    const {moderators} = this.props;
    const {community, name, email} = this.state;
    return (
      <div className="mods">
        <h4>Moderators: (#{moderators.length})</h4>
        <Table responsive className="white-table">
          <thead>
            <tr>
              <th>
                <strong>Since</strong>
              </th>
              <th>
                <strong>FT Community</strong>
              </th>
              <th>
                <strong>Full Name</strong>
              </th>
              <th>
                <strong>Email</strong>
              </th>
              <th>
                <strong>Action</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.renderAggregatedMods()}
            <tr>
              <td></td>
              <td>
                <input
                  name="community"
                  value={community}
                  onChange={this.onHandleChange}
                />
              </td>
              <td>
                <input
                  name="name"
                  value={name}
                  onChange={this.onHandleChange}
                />
              </td>
              <td>
                <input
                  name="email"
                  value={email}
                  onChange={this.onHandleChange}
                />
              </td>
              <td>
                <span
                  className="action"
                  onClick={() => {
                    this.props.addModerator(
                      {community, name, email},
                      this.clear
                    );
                  }}
                >
                  Create new account
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {moderators: state.moderators};
};

export default connect(
  mapStateToProps,
  {fetchModerators, addModerator, deleteModerator}
)(Moderators);
