import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { fetchCountries } from "../../../actions";
import industries from "../../../data/industries";
import "./SearchBar.css";

class SearchBar extends React.Component {
  onClickSearch = () => {
    const { onQuery, industry, keywords } = this.props;
    const query = {};
    if (keywords.length) {
      console.log("has query");
      query.$text = { $search: keywords };
    }
    if (industry !== "all") query["company.industry"] = industry;
    console.log(query);
    onQuery(query);
  };

  render() {
    const { children, onHandleChange, keywords, industry } = this.props;
    return (
      <div className="search_bar">
        <InputGroup>
          <Form.Control
            name="keywords"
            placeholder="Company name, Paris, Product manager, IoT, etc."
            value={keywords}
            onChange={onHandleChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                this.onClickSearch();
              }
            }}
            style={{ marginRight: "5px", borderRadius: "19px 0 0  19px" }}
          />
          <Form.Control
            as="select"
            name="industry"
            value={industry}
            onChange={onHandleChange}
          >
            <option value="all">All</option>
            {industries.map((industry) => (
              <option key={industry}>{industry}</option>
            ))}
          </Form.Control>
          <InputGroup.Append>
            <Button variant="outline-secondary" onClick={this.onClickSearch}>
              Search
            </Button>
          </InputGroup.Append>
        </InputGroup>
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { countries: state.countries };
};

export default withTranslation()(
  connect(mapStateToProps, { fetchCountries })(SearchBar)
);
