import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import {ParallaxProvider} from "react-scroll-parallax";

import reducers from "./reducers";
import App from "./components/App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./i18n";
import "./fonts/Biotif-Bold.ttf";
import "./fonts/Biotif-BlackItalic.ttf";
import "./fonts/Biotif-Regular.ttf";

const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div></div>}>
      <ParallaxProvider>
        <App />
      </ParallaxProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
