import React from "react";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";
import {fetchKpis} from "../../actions";
import {FaFileCsv} from "react-icons/fa";
import config from "../../config";

class Kpi extends React.Component {
  componentDidMount() {
    this.props.fetchKpis();
  }
  renderAggregatedViews() {
    return this.props.kpis.listCompanies.map(company => {
      return (
        <tr key={company._id}>
          <td>{company._id}</td>
          <td>{company.listings}</td>
          <td>{company.views}</td>
          <td>
            <FaFileCsv
              onClick={() =>
                window.open(
                  `${config.api.baseURL}/admin/csv/${company.id}/${company._id}`
                )
              }
              className="csv"
            />
          </td>
        </tr>
      );
    });
  }
  render() {
    if (!this.props.kpis.listCompanies || this.props.kpis.total === undefined)
      return "Nothing to show!";
    else
      return (
        <div className="kpis">
          <h4>Total Views: {this.props.kpis.total}</h4>
          <Table responsive className="white-table">
            <thead>
              <tr>
                <th>
                  <strong>Company</strong>
                </th>
                <th>
                  <strong>Listings</strong>
                </th>
                <th>
                  <strong>Views</strong>
                </th>
                <th>
                  <strong>Details</strong>
                </th>
              </tr>
            </thead>
            <tbody>{this.renderAggregatedViews()}</tbody>
          </Table>
        </div>
      );
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {kpis: state.kpis};
};

export default connect(
  mapStateToProps,
  {fetchKpis}
)(Kpi);
