import React from "react";
import { withTranslation } from "react-i18next";
import "./Footer.css";

function Footer(props) {
  const { t } = props;
  console.log(window, window.innerWidth);
  if (window.innerWidth < 650) {
    return (
      <footer className="footer footer_black">
        <div className="footer_container">
          <ul>
            <li>
              <a href="/">La French Tech Jet</a>
            </li>
            <li>
              <a href="/jobs">{t("menu.jobBoard")}</a>
            </li>

            <li>
              <a href="/jobs/new">Post a job</a>
            </li>
            <li>
              <a href="https://lafrenchtech.com/en/how-france-helps-startups/french-tech-visa/">
                French Tech Visa
              </a>
            </li>
            <li>
              <a href="/faq">{t("footer.FAQ")}</a>
            </li>
            <li>
              <a href="mailto:jobs@frenchtechtaiwan.com">
                {t("footer.contact")}
              </a>
            </li>

            <li>
              <a href="/credits">{t("footer.credits")}</a>
            </li>
          </ul>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <a
              targeẗ="_blank"
              rel="noopener noreferrer"
              href="https://taiwan.lafrenchtech.com"
            >
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/img/footer/ftt.png`}
              />
            </a>
            <a
              targeẗ="_blank"
              rel="noopener noreferrer"
              href="https://lafrenchtech.com/en/how-france-helps-startups/next40-2/"
            >
              <img alt="" src={`${process.env.PUBLIC_URL}/img/footer/ft.png`} />
            </a>
            <a
              targeẗ="_blank"
              rel="noopener noreferrer"
              href="https://lafrenchtech.com/en/how-france-helps-startups/french-tech-visa/"
            >
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/img/footer/choose.png`}
              />
            </a>
          </div>
        </div>
      </footer>
    );
  } else
    return (
      <footer className="footer footer_black">
        <div className="footer_container">
          <a
            targeẗ="_blank"
            rel="noopener noreferrer"
            href="https://taiwan.lafrenchtech.com"
          >
            <img alt="" src={`${process.env.PUBLIC_URL}/img/footer/ftt.png`} />
          </a>
          <ul>
            <li>
              <a href="/">Jobs @LaFrenchTech</a>
            </li>
            <li>
              <a href="/jobs">{t("menu.jobBoard")}</a>
            </li>

            <li>
              <a href="/jobs/new">Post a job</a>
            </li>
            <li>
              <a href="https://lafrenchtech.com/en/how-france-helps-startups/french-tech-visa/">
                French Tech Visa
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="/faq">{t("footer.FAQ")}</a>
            </li>
            <li>
              <a href="mailto:jobs@frenchtechtaiwan.com">
                {t("footer.contact")}
              </a>
            </li>

            <li>
              <a href="/credits">{t("footer.credits")}</a>
            </li>
          </ul>
          <div style={{ width: "20%" }}></div>
          <a
            targeẗ="_blank"
            rel="noopener noreferrer"
            href="https://lafrenchtech.com/en/how-france-helps-startups/next40-2/"
          >
            <img alt="" src={`${process.env.PUBLIC_URL}/img/footer/ft.png`} />
          </a>
          <a
            targeẗ="_blank"
            rel="noopener noreferrer"
            href="https://lafrenchtech.com/en/how-france-helps-startups/french-tech-visa/"
          >
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/img/footer/choose.png`}
            />
          </a>
        </div>
      </footer>
    );
}

export default withTranslation()(Footer);
