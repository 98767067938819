import React from "react";
import { Form, Button } from "react-bootstrap";
class AdminLogin extends React.Component {
  state = {
    user: "",
    pwd: "",
    remember: false
  };

  onSubmitForm = e => {
    e.preventDefault();
    e.stopPropagation();
    const { user, pwd, remember } = this.state;
    const concatAuth = `${user}:${pwd}`;
    this.props.login({ concatAuth, remember });
  };

  render() {
    return (
      <div className="login_div">
        <Form className="admin_login" onSubmit={this.onSubmitForm}>
          <h1>Login</h1>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control
              required
              type="text"
              value={this.state.user}
              onChange={e => {
                this.setState({ user: e.target.value });
              }}
              placeholder="Enter username"
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              value={this.state.pwd}
              onChange={e => {
                this.setState({ pwd: e.target.value });
              }}
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              className="checkbox"
              type="checkbox"
              value={this.state.remember}
              onChange={e => {
                this.setState({ remember: e.target.value });
              }}
              label="Remember credentials"
            />
          </Form.Group>
          <Button className="colored_button" variant="dark" type="submit">
            Login
          </Button>
        </Form>
      </div>
    );
  }
}

export default AdminLogin;
